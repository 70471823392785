/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap');



@font-face {
	font-family: "Poppins"; //This is what we are going to call
	src: url("./assets/fonts/Poppins/Poppins-Regular.ttf");
}

@font-face {
	font-family: "Nunito"; //This is what we are going to call
	src: url("./assets/fonts/Nunito-VariableFont_wght.ttf");
}

@font-face {
	font-family: "Lato Regular"; //This is what we are going to call
	src: url("./assets/fonts/Lato/Lato-Regular.ttf");
}

@font-face {
	font-family: "Lato Bold"; //This is what we are going to call
	src: url("./assets/fonts/Lato/Lato-Bold.ttf");
}




.parent-vertical-center {
	position: relative !important;
}

.child-vertical-center {
	position: absolute !important;
	top: 50% !important;
	left: 50% !important;
	transform: translate(-50%, -50%) !important;
}

ion-header {
	--border-style: none;
}



.page-title {
	font-family: "Lato Regular" !important;
	font-size: 2em !important;
	font-weight: 900 !important;
	padding-left: 13px;
}

.card-title {
	font-family: "Lato Regular" !important;
	font-size: 2em !important;
	font-weight: 900 !important;
}

.subtitle {
	font-family: "Lato Bold" !important;
	font-size: 1.5em !important;
}

.new-style {
	background-color: #eeeeee;
	border: none;
	border-radius: 5px;
	font-size: 1.1em;
	height: 2.5em;
	padding: 13px 10px 10px 43px;
	width: 100%;
	color: #414141;
}

.new-style:focus {
	border: none;
}

.input-icons ion-icon {
	position: absolute;
}

.icon {
	padding: 10px !important;
	color: rgb(109, 109, 109) !important;
	font-size: 1.5em !important;
}

ion-title,
h1,
h2,
h3,
p,
ion-button,
input,
ion-list-header,
ion-label {
	font-family: "Lato Regular" !important;
}

.input-new-style{
	width: calc(100% - 60px);
	background-color: #eeeeee;
	border: none;
	border-radius: 5px;
	font-size: 1.1em;
	height: 2.5em;
	padding: 13px 10px 10px 43px;
}

.input-icon{
	position: absolute;
	padding: 10px !important;
	font-size: 1.5em !important;
}

.input-btn{
	position: relative;
	top: -7px;
}





// Globales Nuevas

ion-content{
    --background: #f2e4d7 !important;
	--padding-start: 16px !important;
	--padding-end: 16px !important;
}

ion-item{

	--padding-start: 0px !important;
	--padding-end: 0px !important;
}

ion-toolbar{
	--border-style: none;
	--background: #f2e4d7 !important;
}

.borrar-btn{
	color: #ED576B !important;
}

swiper-container::part(bullet-active) {
	background-color: gray;
  }



.input-bottom .helper-text,
.input-bottom .counter{
	font-weight: normal !important;
  }

  .calendar-header{
	background-color: red;
    color: white;
  }

.bg-main-color {
	background-color: #f2e4d7 !important;
}

.swiper-button-next{
	color:red !important;
}
.swiper-button-prev{
	color:red !important;
}





